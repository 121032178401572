import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Avatar from '@mui/material/Avatar'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Lottie from "lottie-react"
import bus from "./../animation/bus.json"
import load from "./../animation/loading.json"
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import SendIcon from '@mui/icons-material/Send'
import AlertAnim from './../animation/success.json'

const MainPage = () => {

    let location = useLocation()


    const [data, dataSet] = useState<any>(null)
    const [loading, setLoading] = useState(false)

    const [success, setSuccess] = useState(false)

    const [age, setAge] = useState("1")
    const [text, setText] = useState("")

    useEffect(() => {
        async function fetchMyAPI() {
            await setLoading(true)
            let response = await fetch(`https://server2.d-todoimports.com/api/suggestion/resours${location.pathname}`)
            response = await response.json()
            await dataSet(response)
            await setLoading(false)
        }
        fetchMyAPI()
    }, [])

    const handleChange = (event: SelectChangeEvent) => {
        setAge(event.target.value as string);
    }

    const send = async () => {
        const coment = {
            type: age,
            coment: text
        }
        const box = Object.assign(data, coment)
        console.log(box)
        setSuccess(true)
    }

    return (
        <>
            {
                loading &&  !success &&
                <>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        style={{ minHeight: '100vh' }}
                    >
                        <Grid item xs={4}>
                            <Lottie animationData={load} loop={true} style={{ width: 180, margin: 'auto', background: '#F2F2F2', padding: 20, borderRadius: 32 }} />
                        </Grid>
                    </Grid>
                </>

            }
            {
                data && !loading && !success &&
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >

                    <Grid item xs={4}>
                        <Card>
                            <Card sx={{ maxWidth: 580, alignItems: 'stretch' }}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: 'red' }} aria-label="recipe">
                                            R
                                        </Avatar>
                                    }
                                    title={`${data.nombres} ${data.paterno} ${data.materno}`}
                                    subheader={data.placa}
                                />
                                <CardContent sx={{ p: 3 }}>
                                    <Lottie animationData={bus} loop={true} style={{ width: 180, margin: 'auto', background: '#F2F2F2', padding: 20, borderRadius: 32 }} />

                                    <Alert severity="success" color="warning" sx={{ mt: 3, mb: 4 }}>
                                        <AlertTitle>Ayudanos a mejorar</AlertTitle>
                                        Acontinuacion dejanos tus comentario, sugerecias o reclamos <br /> para mejorar nuestro servicio.
                                    </Alert>

                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={age}
                                            sx={{ mb: 3 }}
                                            label="Tipo"
                                            onChange={handleChange}
                                            fullWidth
                                        >
                                            <MenuItem value={'1'}>Sugerencia</MenuItem>
                                            <MenuItem value={'2'}>Reclamo</MenuItem>
                                            <MenuItem value={'3'}>Otros</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        id="outlined-basic2"
                                        label="Mensaje"
                                        variant="outlined"
                                        sx={{ mb: 3 }}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            setText(event.target.value);
                                        }}
                                    />
                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="error" onClick={send}  disabled={!text} endIcon={<SendIcon />} style={{ width: 180, margin: 'auto', borderRadius: 18, marginBottom: 25 }}>
                                        Enviar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Card>
                    </Grid>
                </Grid>
            }
            {
                success &&
                <>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '100vh' }}
                >
                    <Grid item xs={4}>
                        <Lottie animationData={AlertAnim} style={{ width: 250, margin: 'auto', background: '#F2F2F2', padding: 20, borderRadius: 32 }} />
                        <p style={{textAlign:'center'}}>Gracias por tu aporte, mejoraremos nuestro <br /> servicio dia a dia.</p>
                    </Grid>
                </Grid>
            </>
            }

        </>
    )
}
export default MainPage